export default function () {
  const { loadScript, addServiceKey } = useHelpers()
  const { HELPSCOUT_KEY } = useRuntimeConfig().public

  function loadHelpScout () {
    if (!HELPSCOUT_KEY) return
    if (window.Beacon) return

    console.info('Loading Help Scout...')
    addServiceKey('HELPSCOUT_KEY', HELPSCOUT_KEY)
    loadScript('/scripts/helpScout.js')
  }

  function enableLiveChat (chatEnabled = true) {
    if (!HELPSCOUT_KEY) return
    if (!window.Beacon) return

    console.info(
      chatEnabled
        ? 'Enabling Help Scout live chat'
        : 'Disabling Help Scout live chat...'
    )
    window.Beacon('config', {
      messaging: {
        chatEnabled
      }
    })
  }

  return {
    loadHelpScout,
    enableLiveChat
  }
}
