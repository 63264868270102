export const useOrders = defineStore('orders', () => {
  const store = useBaseStore('orders')

  return {
    ...store
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrders, import.meta.hot))
}
