// Copied from https://www.lichter.io/articles/nuxt3-sentry-recipe/#nuxt-integration-setting-up-the-client-side
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const config = useRuntimeConfig()
  const dsn = config.public.SENTRY_DSN

  if (!dsn) return

  const environment = config.public.ENVIRONMENT_NAME

  import('@sentry/vue').then(Sentry => {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn,
      environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],

      // Configure this whole part as you need it!

      tracesSampleRate: 0.2, // Change in prod

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://your-server.com'],

      replaysSessionSampleRate: 1.0, // Change in prod
      replaysOnErrorSampleRate: 1.0 // Change in prod if necessary
    })
  })
})
