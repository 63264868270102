<script setup>
const emit = defineEmits(['success', 'toggle'])

defineProps({
  showForgotPass: {
    type: Boolean,
    default: true
  }
})

const tech = useTech()
const ui = useUi()
const user = useUser()

const route = useRoute()

const errors = ref({})
const loading = ref(false)
const inputPassword = ref(null)
const form = reactive({
  email: route.query?.email || '',
  password: ''
})

watch(() => route.query, (query) => {
  if (query?.email) {
    form.email = query.email
  }
})

async function submit () {
  errors.value = {}
  loading.value = true

  try {
    await user.login(toRaw(form))
    ui.closeAuthModal()
    emit('success')
  } catch (e) {
    errors.value = e.data?.errors
    loading.value = false
  }
}

onMounted(async () => {
  if (route.query?.email) {
    inputPassword.value.focus()
  }
})
</script>

<template>
  <div
    class="flex w-full shrink-0 flex-col gap-6 rounded-3xl bg-white px-4 py-6 md:w-130 md:p-9 dark:bg-dark-gunmetal"
    :class="{'dark dark:bg-dark-metal': tech.id==='nuxt'}">
    <h1 class="heading-primary text-3xl font-bold md:text-5xl">
      <em>Log In</em>
    </h1>

    <AuthVoucherMessage />

    <AuthSocial />

    <AuthDivider />

    <form
      class="flex w-full flex-col gap-6"
      @submit.prevent="submit">
      <FormInput
        v-model="form.email"
        required
        type="email"
        placeholder="Enter you email"
        label="Your Email"
        :errors="errors?.email" />

      <FormInput
        ref="inputPassword"
        v-model="form.password"
        required
        type="password"
        placeholder="Enter your password"
        label="Your Password"
        :errors="errors?.password" />

      <NuxtLink
        v-if="showForgotPass && (errors?.email || errors?.password)"
        :to="`/${tech.id}/password-recover`"
        target="_blank"
        class="btn-primary-link">
        Forgot your password?
      </NuxtLink>

      <button
        :disabled="loading"
        class="btn-primary w-full px-8 py-4">
        <AppLoading v-if="loading" /> Log In
      </button>
    </form>

    <button
      class="btn-primary-link"
      @click="$emit('toggle')">
      Create an account
    </button>
  </div>
</template>
