<script setup>
const isLight = useCookie('light-theme')

const modes = computed(() => {
  return [
    { id: 'dark', icon: '/images/icon-dark.svg', label: 'Dark Mode', active: !isLight.value },
    { id: 'light', icon: '/images/icon-light.svg', label: 'Light Mode', active: isLight.value }
  ]
})

function toggle () {
  if (isLight.value) {
    window.document.documentElement.classList.add('dark')
    isLight.value = null
  } else {
    window.document.documentElement.classList.remove('dark')
    isLight.value = 1
  }
}
</script>

<template>
  <div
    class="m-4 inline-flex cursor-pointer select-none items-center gap-2 rounded-full bg-anti-flash p-2 text-black transition-transform duration-75 ease-linear md:m-0 hover:xl:scale-105 dark:bg-dark-gunmetal dark:text-white"
    @click="toggle">
    <div
      v-for="mode in modes"
      :key="mode.id">
      <InlineSvg
        :src="mode.icon"
        hard-code
        class="h-4 fill-current"
        :class="{ 'opacity-30': !mode.active }" />
    </div>
  </div>
</template>
