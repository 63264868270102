export default defineNuxtPlugin((nuxtApp) => {
  const { GTM_ID } = nuxtApp.$config.public
  const user = useUser()

  const { loadScript, addServiceKey } = useHelpers()

  nuxtApp.hook('app:mounted', () => {
    console.info('Loading GTM...')

    if (!GTM_ID) {
      console.info('GTM_ID not defined')
      return
    }

    addServiceKey('GTM_ID', GTM_ID)
    loadScript('/scripts/gtm.js', false, false, () => {
      if (!user.isGuest) {
        identify(user.data.email, user.data.id)
      }
    })
  })

  function track (params) {
    console.info('GTM', params)

    if (!GTM_ID) {
      console.info('GTM_ID not defined')
      return
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(params)
  }

  function identify (email, id) {
    if (!GTM_ID) {
      console.info('GTM_ID not defined')
      return
    }

    track({ event: 'identified', userEmail: email, userId: id })
  }

  return {
    provide: {
      gtm: {
        track,
        identify
      }
    }
  }
})
