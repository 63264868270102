export default function () {
  const config = useRuntimeConfig()
  const tech = useTech()

  const socials = computed(() => {
    return [
      {
        name: 'GitHub',
        route: encodeURI(`https://github.com/login/oauth/authorize?redirect_uri=${config.public.APP_ROOT}/login/github/callback${tech.id ? '/' + tech.id : ''}&scope=user:email&client_id=${config.public.GITHUB_CLIENT_ID}`)
      }
    ]
  })

  return {
    socials
  }
}
