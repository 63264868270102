import { startsWith, has, cloneDeep } from 'lodash-es'

export default function () {
  function scrollTo (name) {
    document.getElementById(name).scrollIntoView({ behavior: 'smooth' })
  }

  function loadScript (src, async = true, defer = false, callback = null) {
    const script = document.createElement('script')
    script.src = src
    if (async) script.async = true
    if (defer) script.defer = true
    script.onload = callback
    document.head.appendChild(script)
  }

  function addServiceKey (key, value) {
    window.serviceKeys = window.serviceKeys || {}
    window.serviceKeys[key] = value
  }

  function getCookieParams (params) {
    const config = useRuntimeConfig()
    const secure = startsWith(config.public.APP_ROOT, 'https')

    return {
      path: '/',
      sameSite: secure ? 'none' : 'strict',
      secure,
      ...params
    }
  }

  function formatPrice (amount, symbol = '$') {
    const price = Number(amount / 100).toFixed(2)
    const value = parseInt(price) === Number(price) ? parseInt(price) : price

    return `${symbol}${value}`
  }

  function formatTitle (string, emClass = '') {
    if (!string) return ''
    return string.replace(/\[/g, `<em${emClass?.length ? ` class="${emClass}"` : ''}>`).replace(/\]/g, '</em>')
  }

  function emphasizeChapter (str) {
    return str.replace(/(Chapter \d+): /g, '<em>$1:</em> ')
  }

  function getFlagEmoji (countryCode) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt())
    return String.fromCodePoint(...codePoints)
  }

  function downloadBlob (blob, name) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set link's href to point to the Blob URL
    link.href = blobUrl
    link.download = name

    // Append link to the body
    document.body.appendChild(link)

    // Dispatch click event on the link
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )

    // Remove link from body
    document.body.removeChild(link)
  }

  async function scheduleTimeout (datetimeString, callback) {
    const now = await useExams().now()
    const targetDatetime = new Date(datetimeString).getTime()
    const timeoutDuration = Math.max(targetDatetime - now, 0)

    return setTimeout(() => {
      console.info('Timeout reached!')
      callback()
    }, timeoutDuration)
  }

  function removeFilesKeysFromLocalStorage (value) {
    const keysToRemove = []

    // Iterate through all keys in Local Storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)

      if (key.startsWith(value)) {
        keysToRemove.push(key)
      }
    }

    // Remove the keys
    keysToRemove.forEach(key => {
      console.info(`Removing from localStorage: ${key}`)
      localStorage.removeItem(key)
    })
  }

  function formatDate (dateString) {
    const date = new Date(dateString)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString('en-US', options)
  }

  function parseMarkdown (md) {
    if (import.meta.server) {
      return import('@nuxt/content/transformers/markdown').then(mod => {
        return mod.default.parse('custom.md', md)
      })
    }
    return md
  }

  function countdownTransform (originalProps) {
    if (
      !has(originalProps, 'days') ||
      !has(originalProps, 'hours') ||
      !has(originalProps, 'minutes') ||
      !has(originalProps, 'seconds')
    ) {
      return { days: '00', hours: '00', minutes: '00', seconds: '00' }
    }

    const props = cloneDeep(originalProps)
    Object.entries(props).forEach(([key, value]) => {
      const digits = value < 10 ? `0${value}` : value
      props[key] = digits
    })
    return props
  }

  function countdownTransformDaysToHours (originalProps) {
    if (
      !has(originalProps, 'days') ||
      !has(originalProps, 'hours') ||
      !has(originalProps, 'minutes') ||
      !has(originalProps, 'seconds')
    ) {
      return { days: '00', hours: '00', minutes: '00', seconds: '00' }
    }

    const props = cloneDeep(originalProps)

    if (props.days) {
      props.hours = props.hours + (props.days * 24)
      props.days = 0
    }

    Object.entries(props).forEach(([key, value]) => {
      const digits = value < 10 ? `0${value}` : value
      props[key] = digits
    })
    return props
  }

  function extractSubstring (originalString, identifier) {
    const startPos = originalString.indexOf(identifier)
    if (startPos !== -1) {
      return originalString.substring(startPos)
    }
    return originalString
  }

  function alternateSplitArray (elements) {
    const firstGroup = []
    const secondGroup = []

    elements.forEach((card, index) => {
      if (index % 2 === 0) {
        firstGroup.push(card)
      } else {
        secondGroup.push(card)
      }
    })
    return [firstGroup, secondGroup]
  }

  function getFlashMessageKey (tech) {
    return `${tech?.id?.toUpperCase() || 'CERT'}_SIGN_UP_COMPLETED`
  }

  return {
    scrollTo,
    loadScript,
    addServiceKey,
    getCookieParams,
    formatPrice,
    formatTitle,
    emphasizeChapter,
    downloadBlob,
    getFlagEmoji,
    scheduleTimeout,
    removeFilesKeysFromLocalStorage,
    formatDate,
    parseMarkdown,
    countdownTransform,
    countdownTransformDaysToHours,
    extractSubstring,
    alternateSplitArray,
    getFlashMessageKey
  }
}
