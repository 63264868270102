import { default as indexUUQIwLg0NQMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/certificates/[id]/index.vue?macro=true";
import { default as shareWbtjZyOkoeMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/certificates/[id]/share.vue?macro=true";
import { default as _91slug_936iQoKtTosGMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/bootcamp/[slug].vue?macro=true";
import { default as indexLyGJday1X0Meta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/bootcamp/index.vue?macro=true";
import { default as indexkDasrO07KbMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/exam/index.vue?macro=true";
import { default as indexkvTLuIRDUXMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/free-weekend/index.vue?macro=true";
import { default as _91slug_93iRnrZ4AAHvMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/guides/[slug].vue?macro=true";
import { default as indexiZW3YE9zQ3Meta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/guides/index.vue?macro=true";
import { default as indexqFt8UI9bQoMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/index.vue?macro=true";
import { default as index1IBIw4IcL4Meta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/profile/index.vue?macro=true";
import { default as _91slug_93Ti1BDhin6mMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/training/[slug].vue?macro=true";
import { default as indexVYKKZGzcBLMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/training/index.vue?macro=true";
import { default as indexmh4NroGswmMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/upgrades/index.vue?macro=true";
import { default as index3T8DDiphUYMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/vouchers/index.vue?macro=true";
import { default as disputeSwVgtNxJvEMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/dispute.vue?macro=true";
import { default as doneEW89200sdmMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/done.vue?macro=true";
import { default as progressXf2fjnN2GtMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/progress.vue?macro=true";
import { default as rulesSIRaSEz7qCMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/rules.vue?macro=true";
import { default as startpjdeNJ66bjMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/start.vue?macro=true";
import { default as termsfV0YclFPFUMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/terms.vue?macro=true";
import { default as indexkanhUUjdTZMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/login/index.vue?macro=true";
import { default as indexB1laFWx3AuMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/logout/index.vue?macro=true";
import { default as check_45email9kaY9TuF2jMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-recover/check-email.vue?macro=true";
import { default as indexEr4Dg2fSEWMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-recover/index.vue?macro=true";
import { default as successMnpJUSBWxMMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-recover/success.vue?macro=true";
import { default as _91token_93xY5f3RmgsgMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-reset/[token].vue?macro=true";
import { default as _91token_93ibSiM1IO65Meta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-set/[token].vue?macro=true";
import { default as indexyXXIGdyFV8Meta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/register/index.vue?macro=true";
import { default as done7wmN4393pWMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/done.vue?macro=true";
import { default as progressN8tZfOpVCAMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/progress.vue?macro=true";
import { default as resultsNPp6CbdBa5Meta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/results.vue?macro=true";
import { default as rulesYOM3uaWIayMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/rules.vue?macro=true";
import { default as startXQvK9jTWhnMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/start.vue?macro=true";
import { default as termsakxJY1BCjvMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/terms.vue?macro=true";
import { default as _91hash_93J0C5xwZCNKMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/verify-email/[id]/[hash].vue?macro=true";
import { default as _91code_934Arf272LANMeta } from "/home/runner/work/certificates-app/certificates-app/pages/[tech]/voucher/[code].vue?macro=true";
import { default as indexrjHO63KsAoMeta } from "/home/runner/work/certificates-app/certificates-app/pages/angular/index.vue?macro=true";
import { default as index2H69vCNYljkMeta } from "/home/runner/work/certificates-app/certificates-app/pages/angular/index2.vue?macro=true";
import { default as _91slug_93jf14EWkzQkMeta } from "/home/runner/work/certificates-app/certificates-app/pages/angular/legal/[slug].vue?macro=true";
import { default as successyU3zO6O0CBMeta } from "/home/runner/work/certificates-app/certificates-app/pages/angular/success.vue?macro=true";
import { default as _91slug_93XkW6otCMaQMeta } from "/home/runner/work/certificates-app/certificates-app/pages/blog/[slug].vue?macro=true";
import { default as index0FkMs6PoBQMeta } from "/home/runner/work/certificates-app/certificates-app/pages/blog/index.vue?macro=true";
import { default as successt4j0sKKOEoMeta } from "/home/runner/work/certificates-app/certificates-app/pages/checkout/success.vue?macro=true";
import { default as index0y7rHUPnrVMeta } from "/home/runner/work/certificates-app/certificates-app/pages/index.vue?macro=true";
import { default as indexgYIw8TfuAJMeta } from "/home/runner/work/certificates-app/certificates-app/pages/javascript/index.vue?macro=true";
import { default as successFd0PRBMAjMMeta } from "/home/runner/work/certificates-app/certificates-app/pages/javascript/success.vue?macro=true";
import { default as _91slug_93VSgSim9uBpMeta } from "/home/runner/work/certificates-app/certificates-app/pages/legal/[slug].vue?macro=true";
import { default as _91_91tech_93_93HxuUC9EyqnMeta } from "/home/runner/work/certificates-app/certificates-app/pages/login/[provider]/callback/[[tech]].vue?macro=true";
import { default as indexZn5nKRVcewMeta } from "/home/runner/work/certificates-app/certificates-app/pages/nuxt/index.vue?macro=true";
import { default as _91slug_93EOiemm7DcqMeta } from "/home/runner/work/certificates-app/certificates-app/pages/nuxt/legal/[slug].vue?macro=true";
import { default as successe5WAbfgoMOMeta } from "/home/runner/work/certificates-app/certificates-app/pages/nuxt/success.vue?macro=true";
import { default as press_45releaseK9yQFTgtIpMeta } from "/home/runner/work/certificates-app/certificates-app/pages/press-release.vue?macro=true";
import { default as soonEqZR7p9ANQMeta } from "/home/runner/work/certificates-app/certificates-app/pages/soon.vue?macro=true";
import { default as indexBNoDBdrplSMeta } from "/home/runner/work/certificates-app/certificates-app/pages/vuejs/free-weekend/index.vue?macro=true";
import { default as pollHSYjgo5POaMeta } from "/home/runner/work/certificates-app/certificates-app/pages/vuejs/free-weekend/poll.vue?macro=true";
import { default as successZWOaowlmiEMeta } from "/home/runner/work/certificates-app/certificates-app/pages/vuejs/free-weekend/success.vue?macro=true";
import { default as indexFDZstyuCDsMeta } from "/home/runner/work/certificates-app/certificates-app/pages/vuejs/index.vue?macro=true";
import { default as _91slug_93UbcypnlyyzMeta } from "/home/runner/work/certificates-app/certificates-app/pages/vuejs/legal/[slug].vue?macro=true";
import { default as demoJIMPY8uvKmMeta } from "/home/runner/work/certificates-app/certificates-app/pages/vuejs/qa/demo.vue?macro=true";
import { default as component_45stubMxIl4Y2V0OMeta } from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMxIl4Y2V0O } from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "tech-certificates-id",
    path: "/:tech()/certificates/:id()",
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/certificates/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-certificates-id-share",
    path: "/:tech()/certificates/:id()/share",
    meta: shareWbtjZyOkoeMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/certificates/[id]/share.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-bootcamp-slug",
    path: "/:tech()/dashboard/bootcamp/:slug()",
    meta: _91slug_936iQoKtTosGMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/bootcamp/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-bootcamp",
    path: "/:tech()/dashboard/bootcamp",
    meta: indexLyGJday1X0Meta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/bootcamp/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-exam",
    path: "/:tech()/dashboard/exam",
    meta: indexkDasrO07KbMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/exam/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-free-weekend",
    path: "/:tech()/dashboard/free-weekend",
    meta: indexkvTLuIRDUXMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/free-weekend/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-guides-slug",
    path: "/:tech()/dashboard/guides/:slug()",
    meta: _91slug_93iRnrZ4AAHvMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-guides",
    path: "/:tech()/dashboard/guides",
    meta: indexiZW3YE9zQ3Meta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard",
    path: "/:tech()/dashboard",
    meta: indexqFt8UI9bQoMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-profile",
    path: "/:tech()/dashboard/profile",
    meta: index1IBIw4IcL4Meta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-training-slug",
    path: "/:tech()/dashboard/training/:slug()",
    meta: _91slug_93Ti1BDhin6mMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/training/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-training",
    path: "/:tech()/dashboard/training",
    meta: indexVYKKZGzcBLMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/training/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-upgrades",
    path: "/:tech()/dashboard/upgrades",
    meta: indexmh4NroGswmMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/upgrades/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-dashboard-vouchers",
    path: "/:tech()/dashboard/vouchers",
    meta: index3T8DDiphUYMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/dashboard/vouchers/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-exam-id-dispute",
    path: "/:tech()/exam/:id()/dispute",
    meta: disputeSwVgtNxJvEMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/dispute.vue").then(m => m.default || m)
  },
  {
    name: "tech-exam-id-done",
    path: "/:tech()/exam/:id()/done",
    meta: doneEW89200sdmMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/done.vue").then(m => m.default || m)
  },
  {
    name: "tech-exam-id-progress",
    path: "/:tech()/exam/:id()/progress",
    meta: progressXf2fjnN2GtMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/progress.vue").then(m => m.default || m)
  },
  {
    name: "tech-exam-id-rules",
    path: "/:tech()/exam/:id()/rules",
    meta: rulesSIRaSEz7qCMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/rules.vue").then(m => m.default || m)
  },
  {
    name: "tech-exam-id-start",
    path: "/:tech()/exam/:id()/start",
    meta: startpjdeNJ66bjMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/start.vue").then(m => m.default || m)
  },
  {
    name: "tech-exam-id-terms",
    path: "/:tech()/exam/:id()/terms",
    meta: termsfV0YclFPFUMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/exam/[id]/terms.vue").then(m => m.default || m)
  },
  {
    name: "tech-login",
    path: "/:tech()/login",
    meta: indexkanhUUjdTZMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/login/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-logout",
    path: "/:tech()/logout",
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-password-recover-check-email",
    path: "/:tech()/password-recover/check-email",
    meta: check_45email9kaY9TuF2jMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-recover/check-email.vue").then(m => m.default || m)
  },
  {
    name: "tech-password-recover",
    path: "/:tech()/password-recover",
    meta: indexEr4Dg2fSEWMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-recover/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-password-recover-success",
    path: "/:tech()/password-recover/success",
    meta: successMnpJUSBWxMMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-recover/success.vue").then(m => m.default || m)
  },
  {
    name: "tech-password-reset-token",
    path: "/:tech()/password-reset/:token()",
    meta: _91token_93xY5f3RmgsgMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "tech-password-set-token",
    path: "/:tech()/password-set/:token()",
    meta: _91token_93ibSiM1IO65Meta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/password-set/[token].vue").then(m => m.default || m)
  },
  {
    name: "tech-register",
    path: "/:tech()/register",
    meta: indexyXXIGdyFV8Meta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/register/index.vue").then(m => m.default || m)
  },
  {
    name: "tech-trial-exams-id-done",
    path: "/:tech()/trial-exams/:id()/done",
    meta: done7wmN4393pWMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/done.vue").then(m => m.default || m)
  },
  {
    name: "tech-trial-exams-id-progress",
    path: "/:tech()/trial-exams/:id()/progress",
    meta: progressN8tZfOpVCAMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/progress.vue").then(m => m.default || m)
  },
  {
    name: "tech-trial-exams-id-results",
    path: "/:tech()/trial-exams/:id()/results",
    meta: resultsNPp6CbdBa5Meta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/results.vue").then(m => m.default || m)
  },
  {
    name: "tech-trial-exams-id-rules",
    path: "/:tech()/trial-exams/:id()/rules",
    meta: rulesYOM3uaWIayMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/rules.vue").then(m => m.default || m)
  },
  {
    name: "tech-trial-exams-id-start",
    path: "/:tech()/trial-exams/:id()/start",
    meta: startXQvK9jTWhnMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/start.vue").then(m => m.default || m)
  },
  {
    name: "tech-trial-exams-id-terms",
    path: "/:tech()/trial-exams/:id()/terms",
    meta: termsakxJY1BCjvMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/trial-exams/[id]/terms.vue").then(m => m.default || m)
  },
  {
    name: "tech-verify-email-id-hash",
    path: "/:tech()/verify-email/:id()/:hash()",
    meta: _91hash_93J0C5xwZCNKMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/verify-email/[id]/[hash].vue").then(m => m.default || m)
  },
  {
    name: "tech-voucher-code",
    path: "/:tech()/voucher/:code()",
    meta: _91code_934Arf272LANMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/[tech]/voucher/[code].vue").then(m => m.default || m)
  },
  {
    name: "angular",
    path: "/angular",
    meta: indexrjHO63KsAoMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/angular/index.vue").then(m => m.default || m)
  },
  {
    name: "angular-index2",
    path: "/angular/index2",
    meta: index2H69vCNYljkMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/angular/index2.vue").then(m => m.default || m)
  },
  {
    name: "angular-legal-slug",
    path: "/angular/legal/:slug()",
    meta: _91slug_93jf14EWkzQkMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/angular/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "angular-success",
    path: "/angular/success",
    meta: successyU3zO6O0CBMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/angular/success.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93XkW6otCMaQMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: index0FkMs6PoBQMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    meta: successt4j0sKKOEoMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index0y7rHUPnrVMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "javascript",
    path: "/javascript",
    meta: indexgYIw8TfuAJMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/javascript/index.vue").then(m => m.default || m)
  },
  {
    name: "javascript-success",
    path: "/javascript/success",
    meta: successFd0PRBMAjMMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/javascript/success.vue").then(m => m.default || m)
  },
  {
    name: "legal-slug",
    path: "/legal/:slug()",
    meta: _91slug_93VSgSim9uBpMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "login-provider-callback-tech",
    path: "/login/:provider()/callback/:tech?",
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/login/[provider]/callback/[[tech]].vue").then(m => m.default || m)
  },
  {
    name: "nuxt",
    path: "/nuxt",
    meta: indexZn5nKRVcewMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/nuxt/index.vue").then(m => m.default || m)
  },
  {
    name: "nuxt-legal-slug",
    path: "/nuxt/legal/:slug()",
    meta: _91slug_93EOiemm7DcqMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/nuxt/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nuxt-success",
    path: "/nuxt/success",
    meta: successe5WAbfgoMOMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/nuxt/success.vue").then(m => m.default || m)
  },
  {
    name: "press-release",
    path: "/press-release",
    meta: press_45releaseK9yQFTgtIpMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/press-release.vue").then(m => m.default || m)
  },
  {
    name: "soon",
    path: "/soon",
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/soon.vue").then(m => m.default || m)
  },
  {
    name: "vuejs-free-weekend",
    path: "/vuejs/free-weekend",
    meta: indexBNoDBdrplSMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/vuejs/free-weekend/index.vue").then(m => m.default || m)
  },
  {
    name: "vuejs-free-weekend-poll",
    path: "/vuejs/free-weekend/poll",
    meta: pollHSYjgo5POaMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/vuejs/free-weekend/poll.vue").then(m => m.default || m)
  },
  {
    name: "vuejs-free-weekend-success",
    path: "/vuejs/free-weekend/success",
    meta: successZWOaowlmiEMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/vuejs/free-weekend/success.vue").then(m => m.default || m)
  },
  {
    name: "vuejs",
    path: "/vuejs",
    meta: indexFDZstyuCDsMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/vuejs/index.vue").then(m => m.default || m)
  },
  {
    name: "vuejs-legal-slug",
    path: "/vuejs/legal/:slug()",
    meta: _91slug_93UbcypnlyyzMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/vuejs/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vuejs-qa-demo",
    path: "/vuejs/qa/demo",
    meta: demoJIMPY8uvKmMeta || {},
    component: () => import("/home/runner/work/certificates-app/certificates-app/pages/vuejs/qa/demo.vue").then(m => m.default || m)
  },
  {
    name: component_45stubMxIl4Y2V0OMeta?.name,
    path: "/login",
    component: component_45stubMxIl4Y2V0O
  },
  {
    name: component_45stubMxIl4Y2V0OMeta?.name,
    path: "/register",
    component: component_45stubMxIl4Y2V0O
  }
]