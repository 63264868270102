<script setup>
defineEmits(['close'])

const route = useRoute()
</script>

<template>
  <button
    tabindex="-1"
    class="absolute right-0 top-0 m-2 flex size-10 items-center justify-center rounded-full bg-ghost-white hover:text-black dark:bg-black/50 dark:hover:text-white"
    :class="{
      'text-azure-radiance': route.meta?.theme === 'theme-lapiz',
      'text-mint': route.meta?.theme === 'theme-vue',
      'text-angular-pink': route.meta?.theme === 'theme-angular'
    }"
    @click="$emit('close')">
    <InlineSvg
      src="/images/icon-close.svg"
      class="h-6 fill-current"
      alt="close" />
  </button>
</template>
