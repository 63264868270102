import { pickBy, kebabCase } from 'lodash-es'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { getCookieParams } = useHelpers()

  function request (method, url, params, headers) {
    const tokenCookie = useCookie('jwt', getCookieParams())
    const couponCookie = useCookie('coupon')
    const utmCookie = useCookie('utm')
    const user = useUser()
    const route = useRoute()

    const token = tokenCookie.value || user.token

    let utmHeader = null
    if (utmCookie.value) {
      utmHeader = toRaw(utmCookie.value)
      utmHeader.utm_campaign = kebabCase(utmHeader.utm_campaign)
      utmHeader = JSON.stringify(utmHeader)
      utmHeader = utmHeader.replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
      )
    }

    return $fetch(url, {
      method,
      body: ['POST', 'PATCH', 'PUT'].includes(method) ? params : null,
      query: ['GET', 'DELETE'].includes(method) ? params : null,
      baseURL: config.public.API_URL,
      headers: pickBy({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : null,
        'X-Coupon': couponCookie?.value,
        'X-UTM': utmHeader ? encodeURIComponent(utmHeader) : null,
        'X-Tech': useTech().id || route.params?.tech,
        ...headers
      }),
      onRequest (arg) {
        console.info('[fetch request]', arg.request)
      },
      onRequestError (arg) {
        console.info('[fetch request error]', arg.request, arg.error)
      },
      onResponse (arg) {
        console.info('[fetch response]', arg.request, arg.response.status)
      },
      onResponseError (arg) {
        console.info('[fetch response error]', arg.request, arg.response.status)
      }
    })
  }

  const api = {
    get (url, params = {}, headers = {}) {
      return request('GET', url, params, headers)
    },
    post (url, params = {}, headers = {}) {
      return request('POST', url, params, headers)
    },
    patch (url, params = {}, headers = {}) {
      return request('PATCH', url, params, headers)
    },
    put (url, params = {}, headers = {}) {
      return request('PUT', url, params, headers)
    },
    delete (url, params = {}, headers = {}) {
      return request('DELETE', url, params, headers)
    }
  }

  return {
    provide: {
      api
    }
  }
})
