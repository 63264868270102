<script setup>
import wait from 'waait'

const emit = defineEmits(['success'])

const props = defineProps({
  mode: {
    type: String,
    default: 'register'
  },
  showCloseButton: {
    type: Boolean,
    default: false
  }
})

const ui = useUi()

const mode = ref(props.mode)

async function close () {
  ui.closeAuthModal()
  await wait(300)
  mode.value = props.mode
}

function toggle () {
  mode.value = (mode.value === 'login') ? 'register' : 'login'
}
</script>

<template>
  <AppModal
    :is-open="ui.isAuthModalOpen"
    @close="close">
    <div class="relative w-full max-w-xl">
      <AppCloseButton @close="close" />
      <AuthLogin
        v-if="mode === 'login'"
        v-bind="{ showForgotPass: false }"
        @success="emit('success')"
        @toggle="toggle" />
      <AuthRegister
        v-if="mode === 'register'"
        @success="emit('success')"
        @toggle="toggle" />
    </div>
  </AppModal>
</template>
