<script setup>
const voucherCookie = useCookie('voucher')
const message = computed(() => voucherCookie.value ? 'Please login or sign up to redeem your voucher.' : null)
</script>

<template>
  <div
    v-if="message"
    class="text-white">
    <span class="text-mint">
      &bull;
    </span>
    {{ message }}
  </div>
</template>
