<script setup>
const { countdownTransform, countdownTransformDaysToHours } = useHelpers()

const props = defineProps({
  remaining: {
    type: Number,
    default: 0
  },
  daysToHours: {
    type: Boolean,
    default: false
  },
  boxClass: {
    type: String,
    default: ''
  },
  boxStyle: {
    type: String,
    default: ''
  }
})

function transform (params) {
  return props.daysToHours
    ? countdownTransformDaysToHours(params)
    : countdownTransform(params)
}
</script>

<template>
  <ClientOnly>
    <VueCountdown
      v-if="remaining > 0"
      v-slot="data"
      :time="remaining"
      :transform="transform">
      <div
        class="flex w-fit items-center justify-center rounded  text-[8px] font-extrabold tracking-wider  md:text-[10px] xl:text-sm"
        :class="[boxClass, daysToHours ? 'w-[60px] md:w-[95px] xl:w-[120px]': 'w-[86px] md:w-[108px] xl:w-[150px]' ]"
        :style="boxStyle">
        <div
          v-for="part in data.days !== '00' ? ['days', 'hours', 'minutes', 'seconds'] : ['hours', 'minutes', 'seconds']"
          :key="part"
          class="flex items-center">
          {{ data[part] }}{{ part.charAt(0) }}
          <span v-if="part !== 'seconds'">
            :
          </span>
        </div>
      </div>
    </VueCountdown>
  </ClientOnly>
</template>
