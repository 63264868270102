import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-bash'

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: { Prism }
  }
})
