<script setup>

const props = defineProps({
  error: Object
})

const title = computed(() => {
  if (props.error.statusCode === 404) {
    return 'Not Found'
  } else if (props.error.statusCode === 403) {
    return 'Forbidden'
  } else if (props.error.statusCode >= 500) {
    return 'Server Error'
  }

  return 'Service Error'
})

const isReload = props.error.data === 'reload'

const handleReload = () => {
  window?.location.reload()
}

const handleError = () => clearError({ redirect: '/' })

</script>

<template>
  <div
    class="theme-lapiz relative h-screen overflow-hidden bg-white pb-8 dark:bg-transparent">
    <HomeHeader />
    <div class="hidden xl:block">
      <img
        src="/images/gradient-lapiz-left.svg"
        class="fixed left-0 top-0 -z-0 overflow-hidden rounded-full blur"
        alt="gradient-left">
      <img
        src="/images/gradient-lapiz-right.svg"
        class="fixed right-0 top-0 -z-0 overflow-hidden rounded-l-full  blur"
        alt="gradient-left">
    </div>
    <div class="inset-0 -mt-24 flex h-screen flex-col items-center justify-center pb-28 text-white">
      <h1 class="mb-3 text-5xl text-quartz dark:text-white">
        {{ title }}
      </h1>
      <p class="mb-8 w-5/6 text-center text-lg text-quartz sm:w-4/5 sm:text-2xl lg:w-3/5 dark:text-white">
        {{ error.message }}
      </p>
      <button
        v-if="!isReload"
        class="btn-primary mx-auto inline-flex  w-fit items-center gap-2 self-stretch px-10 py-4 disabled:opacity-50"
        @click="handleError">
        Go to home page
      </button>

      <button
        v-else
        class="btn-primary mx-auto inline-flex  w-fit items-center gap-2 self-stretch px-10 py-4 disabled:opacity-50"
        @click="handleReload">
        Reload page
      </button>
    </div>
  </div>
</template>
