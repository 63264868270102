import { last } from 'lodash-es'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path.startsWith('/c/')) {
    const id = last(to.path.split('/'))
    const certificate = await useCertificates().getOne({ id })

    if (!certificate?.tech) {
      return
    }
    return navigateTo(to.path.replace('/c/', `/${certificate.tech.id}/certificates/`))
  }
})
