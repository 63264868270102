export default defineNuxtPlugin((nuxtApp) => {
  const { FEEDBACK_FISH_PROJECT_ID } = nuxtApp.$config.public
  if (!FEEDBACK_FISH_PROJECT_ID) return

  const { loadScript } = useHelpers()

  nuxtApp.hook('app:mounted', () => {
    console.info('Loading Feedback Fish...')
    loadScript(`https://feedback.fish/ff.js?pid=${FEEDBACK_FISH_PROJECT_ID}`, false, true)
  })
})
