import { isEmpty, last, omit } from 'lodash-es'

export const useTech = defineStore('tech', () => {
  const techs = ['vuejs', 'angular', 'javascript', 'nuxt']
  const techThemes = {
    vuejs: 'theme-vue',
    angular: 'theme-angular',
    javascript: 'theme-javascript',
    nuxt: 'theme-nuxt'
  }
  const officialTechs = ['vuejs', 'nuxt']

  const data = ref({})
  const certifications = ref([])
  const selectedCertification = ref(null)
  const pages = ref([])

  const id = ref(null)

  const techLabel = computed(() => {
    const labels = {
      vuejs: 'Vue.js',
      angular: 'Angular',
      javascript: 'JavaScript',
      nuxt: 'Nuxt.js'
    }

    return labels[id.value]
  })

  const docLink = computed(() => {
    const links = {
      vuejs: 'https://vueschool-vuejs-docs.netlify.app',
      angular: 'https://cert-angular-docs.netlify.app',
      nuxt: 'https://nuxt.com/docs'
    }

    return links[id.value]
  })

  const isPresale = computed(() => {
    return data.value?.status === 'PREORDER'
  })
  const currentTheme = computed(() => techThemes[id.value])

  async function load (tech = null) {
    console.log('Loading tech data...')
    const techId = tech || id.value

    if (isEmpty(data.value) && techId) {
      const response = await $fetch(`/api/techs/${techId}`)

      if (!response) {
        throw new Error('Tech not found')
      }

      data.value = omit(response, ['pages', 'certifications'])
      certifications.value = response?.certifications || []
      useCertifications().selected = certifications.value.find(cert => cert.difficulty_level === certifications.value.length)?.slug
      pages.value = response?.pages || []
    }

    return data.value
  }

  function detectTechRaw (path, params) {
    let techId = ''
    if (params.tech) {
      techId = params.tech
    } else {
      const paths = path.split('/')
      const isSocialLoginCallback = paths.includes('login') && paths.includes('callback')
      const tech = isSocialLoginCallback ? last(paths) : paths[1]

      techId = techs.includes(tech) ? tech : null
    }

    return techId
  }

  async function detectTech (path, params) {
    try {
      console.log('Detecting tech...')

      id.value = detectTechRaw(path, params)

      await load()

      console.log(`Tech: ${id.value}`)
    } catch (e) {
      console.error('Error detecting tech:', e)

      if (process.client) {
        throw createError({
          statusCode: 500,
          statusMessage: 'Something went wrong, Please reload the page.',
          data: 'reload'
        })
      }
    }
  }

  const isOfficial = computed(() => officialTechs.includes(id.value || ''))

  return {
    load,
    detectTech,
    detectTechRaw,
    id,
    data,
    certifications,
    isOfficial,
    selectedCertification,
    pages,
    isPresale,
    techs,
    techThemes,
    currentTheme,
    techLabel,
    docLink
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTech, import.meta.hot))
}
