import { useTech } from './tech'
import { isPast, parseISO } from 'date-fns'

export const useFreeWeekend = defineStore('freeWeekend', () => {
  const data = ref({
    is_enabled: false,
    is_active: false
  })

  const tech = useTech()
  const { $api } = useNuxtApp()

  const loaded = ref(false)
  const isEnded = computed(() => data.value.is_enabled && isPast(data.value.ended_at))
  const isMockExamActive = computed(() => data.value.is_enabled && data.value.mock_exam)
  const certifications = computed(() => data.value.certifications)
  const trainings = computed(() => data.value.trainings)

  function isCertificationForFreeWeekend (certification) {
    return certification && certifications.value.map(item => item.id).includes(certification.id)
  }

  function isTrainingForFreeWeekend (training) {
    return training && trainings.value.map(item => item.id).includes(training.id)
  }

  async function startTrialExam (certification) {
    const exam = await getMockExam(certification.slug)

    // navigate to exam
    navigateTo(`/${tech.id}/trial-exams/${exam.id}/terms`)
  }

  async function getMockExam (certSlug) {
    try {
      const { data: response } = await $api.post('/trial-exams', {
        certification: certSlug,
        free_weekend: tech.id
      })

      return response
    } catch (e) {
      console.log('Error fetching mock exam', e)
    }
  }

  async function init () {
    try {
      const { data: response } = await useFetch(`/api/free-weekend/${tech.id}`)
      data.value = parse(response.value.data)
      loaded.value = true
    } catch (e) {}
  }

  function parse (response) {
    return {
      ...response,
      start_date: parseISO(response.started_at),
      end_date: parseISO(response.ended_at)
    }
  }

  return {
    data,
    loaded,
    init,
    isEnded,
    isMockExamActive,
    getMockExam,
    certifications,
    trainings,
    isCertificationForFreeWeekend,
    isTrainingForFreeWeekend,
    startTrialExam
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFreeWeekend, import.meta.hot))
}
