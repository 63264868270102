<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

const route = useRoute()

defineEmits(['close'])

defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <TransitionRoot
    :show="isOpen"
    as="template">
    <Dialog @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 z-[60] bg-black/50" />
      </TransitionChild>

      <div
        class="fixed inset-0 z-[70] overflow-y-auto"
        :class="route.meta?.theme">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">
          <div class="flex min-h-full items-center justify-center p-4">
            <DialogPanel class="w-full md:w-fit">
              <slot />
            </DialogPanel>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
