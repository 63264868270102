<script setup>
const emit = defineEmits(['success', 'toggle'])

const tech = useTech()

const props = defineProps({
  queue: {
    type: Object,
    default: null
  }
})

const ui = useUi()
const user = useUser()

const route = useRoute()

const termsLink = '/legal/cert-terms-conditions'
const errors = ref({})
const loading = ref(false)
const inputPassword = ref(null)
const requireName = ref(false)
const name = ref('')
const form = reactive({
  first_name: null,
  last_name: null,
  email: route.query?.email || '',
  password: ''
})

watch(() => route.query, (query) => {
  if (query?.email) {
    form.email = query.email
  }
})

async function submit () {
  errors.value = null
  loading.value = true

  const [firstName, ...lastNameArray] = name.value.split(' ')
  const lastName = lastNameArray.join(' ')

  if (props.queue) user.addToQueue(props.queue)

  try {
    await user.register({ ...toRaw(form), last_name: lastName, first_name: firstName })
    ui.closeAuthModal()
    emit('success')
  } catch (e) {
    errors.value = e.data?.errors
    loading.value = false
  }
}
</script>

<template>
  <div
    class="flex flex-col gap-6 rounded-3xl bg-white px-4 py-6 md:w-130 md:p-9 dark:bg-dark-gunmetal"
    :class="{'dark dark:bg-dark-metal': tech.id === 'nuxt'}">
    <h1 class="heading-primary text-3xl font-bold md:text-5xl">
      <em>Sign Up</em>
    </h1>
    <span class="text-sm text-body-gray">Your choice could be the next certification program. Sign up for updates, share your input, and don’t miss out.</span>

    <AuthVoucherMessage />

    <AuthSocial v-bind="{ mode: 'register', queue }" />

    <AuthDivider />

    <form
      class="flex w-full flex-col gap-6"
      @submit.prevent="submit">
      <FormInput
        v-model="name"
        required
        type="text"
        label="Your Full Name"
        placeholder="Enter your full name" />

      <FormInput
        v-if="requireName"
        v-model="form.last_name"
        required
        type="text"
        placeholder="Enter your name"
        label="Your Last name"
        :errors="errors?.last_name" />

      <FormInput
        v-model="form.email"
        required
        type="email"
        placeholder="Enter your email"
        label="Your Email"
        :errors="errors?.email" />

      <FormInput
        ref="inputPassword"
        v-model="form.password"
        required
        type="password"
        placeholder="Enter your password"
        label="Choose Your Password"
        :errors="errors?.password" />

      <div class="text-center text-sm font-light text-body-gray">
        <span>
          By creating this account you agreed with our
        </span>
        <NuxtLink
          :to="termsLink"
          target="_blank"
          class="underline">
          Terms & Conditions
        </NuxtLink>
      </div>

      <button
        :disabled="loading"
        class="btn-primary w-full px-8 py-4">
        <AppLoading v-if="loading" /> Sign Up
      </button>
    </form>

    <button
      class="btn-primary-link"
      @click="emit('toggle')">
      I already have an account
    </button>
  </div>
</template>
