export default defineNuxtPlugin((nuxtApp) => {
  const { GTAG_ID } = nuxtApp.$config.public
  if (!GTAG_ID) return

  const { loadScript, addServiceKey } = useHelpers()

  nuxtApp.hook('app:mounted', () => {
    console.info('Loading GTAG...')

    if (!GTAG_ID) {
      console.info('GTM_ID not defined')
      return
    }

    addServiceKey('GTAG_ID', GTAG_ID)
    loadScript('/scripts/gtag.js')
  })
})
