<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'

const now = new Date()
const config = useRuntimeConfig()
const route = useRoute()

const { data } = await useFetch(config.public.BANNER_JSON_URL, {
  query: { affiliate: 'certificates_nuxt' },
  getCachedData (key) {
    return useNuxtData(key).data.value
  }
})

const user = useUser()
const { list: certifications } = useCertifications()
const hasPurchased = certifications.some(certification => user.accessTo(certification.id, 'EXAM'))

const phase = data.value?.flat()
  .map((item) => ({
    ...item,
    dateString: item.ends,
    remaining: new Date(item.ends) - now,
    header: stylize(item.header),
    title: stylize(item.title)
  }))
  .find(item => item.remaining > 0)

function stylize (string) {
  return string?.replace('[', '<span class="bg-gradient-to-r from-cyber to-monday bg-clip-text text-transparent">').replace(']', '</span>')
}

const isBannerClosed = ref(true)

const closeBanner = () => {
  isBannerClosed.value = true
  localStorage.setItem('banner', false)
}

onMounted(() => {
  const banner = localStorage?.getItem('banner')

  if (banner === null) {
    isBannerClosed.value = false
  } else {
    isBannerClosed.value = true
  }
})

const showBannerOnLanding = computed(() => {
  if (route.path !== '/') {
    return true
  } else {
    return phase.showOnHomepage || false
  }
})
</script>

<template>
  <NuxtLink
    v-show="!isBannerClosed && (user.isGuest || !hasPurchased) && phase && showBannerOnLanding"
    to="/nuxt#pricing"
    :target="route.path === '/' ? '_blank' : '_self'"
    class="dark group flex h-12 w-full items-center justify-center gap-3 bg-[#022F34] px-4 md:gap-5 md:px-6 xl:gap-10">
    <div
      class="flex items-center gap-2 md:gap-4">
      <InlineSvg
        hard-code
        src="/images/icon-fire.svg"
        class="h-6 w-auto text-nuxt-green" />
      <div
        class="hidden text-xs font-bold text-nuxt-green md:block xl:text-base"
        v-html="$route.path === '/' ? phase?.prelude : phase?.homePrelude" />
    </div>

    <div class="flex flex-row items-center gap-2 md:flex-row md:gap-5">
      <div class="flex flex-col items-center xl:flex-row xl:gap-8">
        <div
          class="whitespace-nowrap text-center text-[8px] font-bold text-nuxt-green md:hidden xl:text-base"
          v-html="$route.path === '/' ? phase?.prelude : phase?.homePrelude" />
        <div
          v-if="phase?.header || phase?.homeHeader"
          class="banner-text whitespace-nowrap text-center text-[8px] font-semibold text-white md:block md:w-auto md:text-xs xl:w-3/5 xl:text-base"
          v-html="$route.path === '/' ? phase?.header : phase?.homeHeader || phase?.header" />

        <div
          v-if="phase?.subtitle"
          class="banner-text whitespace-nowrap text-center text-[8px] font-semibold text-white md:block md:w-auto md:text-xs xl:w-3/5 xl:text-base"
          v-html="phase?.subtitle" />
      </div>

      <button
        v-if="phase?.cta && $route.path !== '/nuxt'"
        class="hidden h-[30px]  items-center justify-center rounded-md bg-bittersweet px-4 text-xs  font-bold text-eerie-black group-hover:bg-white md:inline-flex">
        {{ phase?.cta }}
      </button>

      <div class="flex flex-col items-center gap-0 md:flex-row">
        <p
          v-if="phase?.addendumBefore"
          class="rounded-md text-center text-[10px] font-bold uppercase text-white md:bg-nuxt-green/20 md:px-4 md:py-1">
          {{ phase?.addendumBefore }}
        </p>
        <div
          v-if="phase?.addendum"
          class="text-center text-[10px] font-bold text-nuxt-green md:px-4 md:py-1 xl:text-lg"
          style="">
          {{ phase?.addendum }}
        </div>
      </div>

      <div
        v-if="phase?.showCountdown"
        class="flex flex-col items-center md:flex-row md:gap-4">
        <span class="text-gradient-primary whitespace-nowrap text-[8px] font-bold uppercase md:text-[10px] md:tracking-widest">
          {{ phase?.countdownFooter }}
        </span>
        <AppCountdown
          v-bind="{ remaining: phase?.remaining, daysToHours: phase?.showDaysAsHours }"
          class="dark"
          box-class="text-white lg:scale-[1.2] lg:translate-x-2 p-1 md:px-2 md:py-1"
          :box-style="'background: rgba(0, 220, 130, 0.20);'" />
      </div>
    </div>
    <button
      class="absolute right-2 top-4 xl:right-3"
      @click="closeBanner">
      <span class="sr-only">close</span><XMarkIcon class="x-5 h-5 text-white" />
    </button>
  </NuxtLink>
</template>

<style scoped>
.banner-text :deep(span) {
  @apply  text-nuxt-green
}
</style>
