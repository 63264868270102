export const useProducts = defineStore('products', () => {
  const store = useBaseStore('products')

  const products = computed(() => store.list.value)

  const trainingProducts = computed(() => store.list.value.filter(product => product.type === 'TRAINING'))

  const bootcampProducts = computed(() => store.list.value.filter(product => product.type === 'BOOTCAMP'))

  const examProducts = computed(() => store.list.value.filter(product => product.type === 'EXAM'))

  return {
    ...store,
    products,
    examProducts,
    trainingProducts,
    bootcampProducts
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProducts, import.meta.hot))
}
