export const useCertificates = defineStore('certificates', () => {
  const store = useBaseStore('certificates')

  return {
    ...store
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCertificates, import.meta.hot))
}
