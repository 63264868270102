import { useTech } from './tech'
import { useSummaries } from '~/stores/summaries'

export const useCertifications = defineStore('certifications', () => {
  const tech = useTech()
  const selected = ref(null)
  const selectedLevel = ref('senior')

  const displayLevels = {
    junior: 'Junior',
    intermediate: 'Intermediate',
    senior: 'Senior'
  }

  const list = computed(() => tech.certifications)

  const trial = computed(() => tech.certifications.find(cert => cert.slug.includes('trial')))

  const senior = computed(() => tech.certifications.find(cert => cert.display_level === 'senior' || cert.difficulty_level === 3))

  const mid = computed(() => tech.certifications.find(cert => cert.display_level === 'intermediate' || cert.difficulty_level === 2))

  const junior = computed(() => tech.certifications.find(cert => cert.display_level === 'junior' || cert.difficulty_level === 1))

  async function getTrialSummary () {
    return await useSummaries().getOne(
      { slug: trial.value.slug },
      { fresh: true }
    )
  }

  return { list, selectedLevel, selected, senior, mid, junior, trial, displayLevels, getTrialSummary }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCertifications, import.meta.hot))
}
