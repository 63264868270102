<script setup>
import InlineSvg from 'vue-inline-svg'
const props = defineProps({
  src: String,
  hardCode: Boolean
})
const modules = import.meta.glob([
  '@/public/images/*.svg',
  '@/public/images/*/*-badge*.svg',
  '@/public/images/homepage/*.svg',
  '!**/vuejs-success.svg' // This svg is huge!
])

const svgComponent = modules[`/public${props.src}`]
const useHardCode = svgComponent && props.hardCode
const component = useHardCode ? await svgComponent() : InlineSvg
</script>

<template>
  <component
    :is="component"
    :src="useHardCode ? null : src" />
</template>
