export default defineNuxtPlugin((nuxtApp) => {
  const { MIXPANEL_TOKEN } = nuxtApp.$config.public

  console.log('ℹ️ Injecting MixPanel', MIXPANEL_TOKEN)

  let mixpanel
  async function getMixPanel () {
    if (!MIXPANEL_TOKEN || process.server) {
      return {
        track () {
          console.warn('mixpanel is disabled because there is no MIXPANEL_TOKEN or running on server')
        }
      }
    }
    if (mixpanel) return mixpanel

    const { default: module } = await import('mixpanel-browser')
    mixpanel = module
    if (process.client && MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN)
    }
    return mixpanel
  }

  return {
    provide: {
      mixpanel:
      new Proxy({}, {
        get (target, propertyName) {
          if (mixpanel) return Reflect.get(mixpanel, propertyName)

          if (propertyName === 'track') {
            return async (...args) => {
              (await getMixPanel()).track(...args)
            }
          }
          getMixPanel()
          console.log('[MixPanel proxy] not ready')
        }
      })
    }
  }
})
