import { findIndex } from 'lodash-es'
import { parseISO } from 'date-fns'

export const useExams = defineStore('exams', () => {
  const {
    isLoading,
    list,
    getOne,
    getAll,
    reset,
    reload,
    firstWhere,
    lastWhere
  } = useBaseStore('exams', parseExam)

  const instructionScrollPosition = ref(0)
  const { $api } = useNuxtApp()

  async function now () {
    const { data } = await useFetch('/api/datetime')

    return parseISO(data.value.serverTime)
  }

  function parseExam (exam) {
    const { id } = exam
    return {
      ...exam,
      isTimedOut: false,
      async reload () {
        const { data } = await $api.get(`exams/${id}`)
        const index = findIndex(list.value, { id })

        Object.entries(data).forEach(([key, value]) => {
          list.value[index][key] = value
        })

        Object.assign(this, data)
      },
      start () {
        return $api.post(`exams/${id}/start`)
      },
      stop () {
        return $api.post(`exams/${id}/stop`)
      },
      fileDispute (payload) {
        return $api.post(`exams/${id}/disputes`, payload)
      },
      getExercises (type) {
        return $api.get(`exams/${id}/exercises`, { type })
      },
      solveQuestions (answers) {
        return $api.post(`exams/${id}/exercises/questions/solve`, { answers })
      },
      getCodingChallengeFiles (exerciseId) {
        return $api.get(`exams/${id}/exercises/challenges/${exerciseId}/files`)
      },
      solveCodingChallenge (exerciseId, files) {
        return $api.post(`exams/${id}/exercises/challenges/${exerciseId}/solve`, { files })
      }
    }
  }

  return {
    isLoading,
    list,
    getOne,
    getAll,
    reset,
    reload,
    firstWhere,
    lastWhere,
    instructionScrollPosition,
    now
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExams, import.meta.hot))
}
