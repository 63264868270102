export const useVouchers = defineStore('vouchers', () => {
  const store = useBaseStore('vouchers')

  const { $api } = useNuxtApp()

  async function redeem (code) {
    return await $api.post(`/vouchers/${code}/redeem`)
  }

  function use (code) {
    return $api.post(`/vouchers/${code}/use`)
  }

  async function fetchCombos (params) {
    return (await $api.get('/voucher-combos', params)).data
  }

  async function sendVoucher ({ code, email }) {
    return await $api.post(`/vouchers/${code}/send`, { email })
  }

  return {
    ...store,
    redeem,
    use,
    fetchCombos,
    sendVoucher
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVouchers, import.meta.hot))
}
