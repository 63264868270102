export default defineNuxtPlugin((nuxtApp) => {
  const scriptId = 'recaptcha'
  const token = ref(null)
  const sitekey = nuxtApp.$config.public?.RECAPTCHA_SITE

  function init () {
    // Abort if the config is not present
    if (!sitekey) {
      console.info('reCaptcha site key not defined, skip setup')
      return
    }

    // Abort if we already added script to head
    if (document.getElementById(scriptId)) {
      console.info('reCaptcha script has been already added')
      return
    }

    // Render when the script loads
    window.reCaptchaOnLoad = () => {
      try {
        window.reCaptchaId = grecaptcha.render('recaptcha-container', {
          sitekey,
          size: 'invisible',
          callback: 'reCaptchaOnExecute'
        })
      } catch (e) {
        console.warn('reCaptcha failed to render', e)
      }
    }

    // Define the function that will run as execute callback
    window.reCaptchaOnExecute = (result) => {
      token.value = result
    }

    // Add script tag to head
    const script = document.createElement('script')
    script.async = true
    script.id = scriptId
    script.src = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaOnLoad&render=explicit'
    document.head.appendChild(script)

    // Add div container
    const div = document.createElement('div')
    div.setAttribute('id', 'recaptcha-container')
    document.body.prepend(div)
  }

  function getToken () {
    return new Promise((resolve) => {
      if (!sitekey) {
        console.info('reCaptcha site key not defined, return null token')
        resolve(null)
      }

      const unwatch = watch(token, (newToken) => {
        unwatch()
        grecaptcha.reset(window.reCaptchaId)
        resolve(newToken)
      })
      grecaptcha.execute(window.reCaptchaId)
    })
  }

  nuxtApp.hook('app:mounted', () => {
    console.log('Loading reCaptcha...')
    const route = useRoute()

    if (route.path === '/') {
      window.setTimeout(() => init(), 100)
    } else {
      init()
    }
  })

  return {
    provide: {
      captcha: {
        getToken
      }
    }
  }
})
