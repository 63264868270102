export const useSummaries = defineStore('summaries', () => {
  const store = useBaseStore('summaries/exam')

  return {
    ...store
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSummaries, import.meta.hot))
}
